// UserDetailsDialog.tsx
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

interface UserDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  user: {
    uuid?: string;
    school?: string;
    profession?: string;
    discipline?: string;
    how_heard_us?: string;
    finished_signup: boolean;
    last_login?: string;
    stars?: number;
  } | null;
  updateUserStars: (uuid: string, stars: number) => Promise<void>;
  getUserStars: (uuid: string) => Promise<number>;
}

const UserDetailsDialog: React.FC<UserDetailsDialogProps> = ({ open, onClose, user, updateUserStars, getUserStars }) => {
  const [stars, setStars] = useState<number | undefined>(user?.stars);
  const [newStars, setNewStars] = useState<string>("");

  if (!user) return null;

  const handleRefreshStars = async () => {
    if (user.uuid) {
      const updatedStars = await getUserStars(user.uuid);
      setStars(updatedStars);
    }
  };

  const handleUpdateStars = async () => {
    if (user.uuid && newStars) {
      const starsNumber = parseInt(newStars);
      if (!isNaN(starsNumber) && starsNumber >= 0) {
        await updateUserStars(user.uuid, starsNumber);
        setStars(starsNumber);
        setNewStars("");
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>User Details</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" alignItems="center" mb={2} gap={1}>
          <Typography variant="body1">
            <strong>Stars:</strong> {stars || 0}
          </Typography>
          <IconButton onClick={handleRefreshStars} size="small">
            <RefreshIcon />
          </IconButton>
          <TextField
            label="New Stars"
            type="number"
            size="small"
            value={newStars}
            onChange={(e) => setNewStars(e.target.value)}
            inputProps={{ min: 0 }}
          />
          <Button variant="contained" onClick={handleUpdateStars} disabled={!newStars}>
            Update Stars
          </Button>
        </Box>
        <Typography variant="body1">
          <strong>School:</strong> {user.school || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>Profession:</strong> {user.profession || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>Discipline:</strong> {user.discipline || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>How Heard Us:</strong> {user.how_heard_us || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>Finished Sign-Up:</strong> {user.finished_signup ? "Yes" : "No"}
        </Typography>
        <Typography variant="body1">
          <strong>Last Login:</strong> {user.last_login || "N/A"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailsDialog;
